import React, {Component} from 'react'
import Wrapper from '../components/Wrapper';
import PageHeader from "../components/PageHeader";
import {graphql} from "gatsby";
import SubCatListing from "../components/SubCatListing";
import {Col, Row} from "antd";
// import Banner from "../components/Banner";
import BannerThin from "../components/BannerThin";
import FramedBanner from "../components/FramedBanner";
import {shuffle} from "../services/helpers";

class Landing extends Component {
    state = {
        depth:       "",
        headerImage: undefined,
        slides:      []
    }

    componentDidMount = function () {
        // const page      = this.props.data.wordpressPage;
        const acfFields = this.props.data.wordpressPage.acf;

        let slides      = [];
        let headerImage = "";
        let depth       = "";

        if (acfFields.header_images !== null && acfFields.header_images !== undefined) {
            if (acfFields.header_images.length > 0) {
                slides = [];
                acfFields.header_images.forEach(element =>
                    slides.push(element.localFile.childImageSharp.fluid)
                );
            }

            headerImage = acfFields.header_images[0].localFile.childImageSharp.fluid;
            depth       = "full";
        } else {
            headerImage = this.props.data.allWordpressAcfOptions.edges[0].node.options.default_header_image.localFile.childImageSharp.fluid;
            depth       = "thin";
        }

        this.setState({
            depth:       depth,
            headerImage: headerImage,
            slides:      shuffle(slides)
        });
    }

    render() {
        const page      = this.props.data.wordpressPage;
        const acfFields = this.props.data.wordpressPage.acf;

        const cats = this.props.data.allWordpressWpLocationType.edges

        let seoTitle       = acfFields.meta_title;
        let seoDescription = acfFields.meta_description;
        if (seoTitle === '') {
            seoTitle = page.title;
        }

        let path = this.props.data.wordpressPage.slug;
        if (path === 'home') path = '';
        // add a trailing slash
        if (path.substr(-1) !== '/' && path !== '') path += '/';


        return (
            <Wrapper
                title={seoTitle}
                description={seoDescription}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : null}
                path={`/${path}`}
                locale={page.translations.current}
                translations={page.translations}
            >
                <PageHeader
                    title={acfFields.display_header}
                    videoID={acfFields.header_video_id}
                    image={this.state.headerImage}
                    slides={this.state.slides}
                    headerColor={acfFields.header_colour}
                    headerStyle={acfFields.header_style}
                    back=""
                    date=""
                    depth={this.state.depth}
                />
                <div className="intro quote-intro">
                    <Row gutter={70} type={"flex"}
                         style={{backgroundImage: "url('" + acfFields.intro_image.localFile.childImageSharp.fluid.src + "')"}}>
                        <Col xs={{span: 24}} md={{span: 20}} lg={{span: 18}}>
                            <div dangerouslySetInnerHTML={{__html: acfFields.intro_para}}/>
                        </Col>
                        <Col xs={{span: 14}} md={{span: 10, push: 4}} lg={{span: 10, push: 8}}>
                            <blockquote data-color={acfFields.header_colour}>
                                <p>{acfFields.into_quote}</p>
                            </blockquote>
                        </Col>
                    </Row>
                </div>
                <SubCatListing
                    title={acfFields.listing_title}
                    locale={page.translations.current}
                    cats={cats}
                    parent={this.props.data.wordpressPage.slug}
                />
                <FramedBanner
                    bgImage={acfFields.framed_banner_bg_image.localFile.childImageSharp.fluid.src}
                    topCon={acfFields.top_content}
                    bottomCon={acfFields.bottom_content}
                    btnLink={acfFields.framed_banner_button_link}
                    btnText={acfFields.framed_banner_button_text}
                    btnColor={acfFields.framed_banner_button_colour}
                />
                {/* <Banner
                    bgColor={acfFields.banner_background_colour}
                    image={acfFields.banner_image}
                    headerColor={acfFields.banner_header_colour}
                    header={acfFields.banner_header}
                    paraColor={acfFields.banner_para_colour}
                    para={acfFields.banner_paragraph}
                    btnLink={acfFields.banner_button_link}
                    btnText={acfFields.banner_button_text}
                    btnColor={acfFields.banner_button_colour}
                /> */}
                <BannerThin
                    bgColor={acfFields.thin_banner_background_colour}
                    headerColor={acfFields.thin_banner_header_colour}
                    header={acfFields.thin_banner_header}
                    paraColor={acfFields.thin_banner_para_colour}
                    para={acfFields.thin_banner_paragraph}
                    btnLink={acfFields.thin_banner_button_link}
                    btnText={acfFields.thin_banner_button_text}
                    btnColor={acfFields.thin_banner_button_colour}
                />
            </Wrapper>
        )
    }
}

export const pageQuery = graphql`
    query QueryLandingPage($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            slug
            link
            wordpress_id
            wordpress_parent
            title
            translations {
                current
                en
                cy
            }
            acf {
                meta_title
                meta_description
                og_title
                og_description
                og_image {
                    localFile {
                        childImageSharp {
                            original {
                                width
                                height
                                src
                            }
                        }
                    }
                }
                twitter_title
                twitter_card_type
                twitter_description
                twitter_image {
                    localFile {
                        childImageSharp {
                            original {
                                width
                                height
                                src
                            }
                        }
                    }
                }

                header_style
                header_colour
                header_video_id
                header_images {
                    localFile {
                        childImageSharp {
                            original {
                                width
                                height
                                src
                            }
                            fluid(quality: 80, maxWidth: 1440, maxHeight: 640) {
                                ... GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                display_header
                show_back_link

                intro_para
                into_quote
                intro_image {
                    localFile {
                        childImageSharp {
                            original {
                                width
                                height
                                src
                            }
                            fluid(quality: 80, maxWidth: 225, maxHeight: 420) {
                                ... GatsbyImageSharpFluid
                            }
                        }
                    }
                }

                listing_title

                framed_banner_bg_image {
                    localFile {
                        childImageSharp {
                            original {
                                width
                                height
                                src
                            }
                            fluid(quality: 80, maxWidth: 1920, maxHeight: 1000) {
                                ... GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                top_content
                bottom_content
                framed_banner_button_colour
                framed_banner_button_text
                framed_banner_button_link

                banner_background_colour
                banner_image {
                    localFile {
                        childImageSharp {
                            original {
                                width
                                height
                                src
                            }
                            fluid(quality: 80) {
                                ... GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                banner_header_colour
                banner_para_colour
                banner_header
                banner_paragraph
                banner_button_colour
                banner_button_text
                banner_button_link

                thin_banner_background_colour
                thin_banner_header_colour
                thin_banner_para_colour
                thin_banner_header
                thin_banner_paragraph
                thin_banner_button_colour
                thin_banner_button_text
                thin_banner_button_link
            }
        },
        allWordpressWpLocationType(sort: {fields: menu_order, order: ASC},filter: {wordpress_parent: {ne: 0}}) {
            edges {
                node {
                    id
                    title
                    slug
                    translations {
                        current
                        en
                        cy
                    }
                    parent_element {
                        slug
                        acf {
                            listing_colour
                        }
                    }
                    acf {
                        listing_blurb
                        listing_image {
                            localFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        listing_colour
                        locations_count
                    }
                }
            }
        }
    }
`

export default Landing
